import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Scrollspy from 'react-scrollspy'
import Scroll from '../../components/Scroll'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import Nav from '../../components/Nav'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import apple_budge from '../../assets/images/App_Store_Badge_US-UK_135x40.png'
import ddg02 from '../../assets/images/ddg/ddg_sc2.jpg'
import ddg03 from '../../assets/images/ddg/ddg_sc3.jpg'
import ddg04 from '../../assets/images/ddg/ddg_sc4.jpg'

const DeathDetectiveGirl = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const ddg01 = '../../assets/images/ddg/ddg_sc1.jpg'

  return (
    <Layout>
      <Helmet title="死神探偵少女" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="top" className="main special">
          <header className="major">
              <h2>死神探偵少女</h2>
          </header>
          <span className="image major aligncenter"><StaticImage src={ddg01} alt="" width="640" /></span>
          <footer className="major">
            <p>"死神の館"で行われる狂気のデスゲーム。証拠と推理で真相を暴き、失われた記憶を取り戻せ。</p>
          </footer>
        </section>
        <section id="index" className="main">
          <Scrollspy items={ ['download', 'movie', 'content', 'sc', 'gide'] } currentClassName="is-active" offset={-300}></Scrollspy>
          <ul>
            <li>
              <Scroll type="id" element="download">
                  <a href="#download">ダウンロード</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="movie">
                <a href="#movie">ムービー</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="content">
                <a href="#content">内容</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="sc">
                <a href="#sc">スクリーンショット</a>
              </Scroll></li>
            <li>
              <Scroll type="id" element="guide">
                <a href="#guide">記事、SNS投稿、動画配信等のガイドライン</a>
              </Scroll>
            </li>
          </ul>
        </section>
        <section id="download" className="main">
          <header className="major">
            <h2>ダウンロード</h2>
          </header>
          <div className="horizon-budges">
            <a href="https://itunes.apple.com/jp/app/id1422693291?mt=8" target="_blank" rel="noopener noreferrer" className="icon"><img className="apple" src={apple_budge} alt="" /></a>
            <a href="https://play.google.com/store/apps/details?id=net.cretia.deathgirl" target="_blank" rel="noopener noreferrer" className="icon"><img className="google" src="https://play.google.com/intl/ja/badges/images/generic/en_badge_web_generic.png" alt="Get it on Google Play" width="155" height="60" /></a>
          </div>
        </section>
        <section id="movie" className="main">
          <header className="major">
            <h2>ムービー</h2>
          </header>
          <div className="video-player">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/3Wywn0J92Ec?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </section>
        <section id="content" className="main">
          <header className="major">
            <h2>内容</h2>
          </header>
          <h3>この5人の高校生の中に、死神がいる</h3>
          <p>死神の凶行を乗り越えた先に行われる投票ゲーム。<br />
            このデスゲームに勝たなければ、"死神の館"から脱出することはできない。</p>
          <p>記憶を失った主人公に襲いかかる、絶体絶命のピンチ。<br />
          論理と証拠で無実を証明せよ！</p>

          <h3>館を探索して手がかりを見つけろ！</h3>
          <p>事件現場や館内をくまなく調査し、証拠や参加者たちの証言を集めよう。<br />
          些細なことも、事件の大きなカギとなる！</p>

          <h3>白熱する議論で犯人を暴け！</h3>
          <p>投票ゲームで一番投票数が多かった者は"死神の館"で居残りとなる。<br />
          集めた証拠を議論でつきつけ、真相を解き明かそう！</p>

          <hr />

          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>死神探偵少女</td></tr>
              <tr><th>プラットフォーム</th><td>iOS 10.0 以上 / Android 4.4 以上</td></tr>
              <tr><th>ジャンル</th><td>推理サスペンスADV</td></tr>
              <tr><th>提供形態</th><td>無料ゲーム(広告、課金コンテンツあり)</td></tr>
              <tr><th>リリース日</th><td>2018年 9月 27日</td></tr>
            </tbody>
          </table>
        </section>
        <section id="sc" className="main">
          <header className="major">
            <h2>スクリーンショット</h2>
          </header>
          <Carousel className="carousel-custom" width={340} showArrows={true} showThumbs={true} showIndicators={false}>
              <div>
                  <img src={ddg02} alt="" />
              </div>
              <div>
                  <img src={ddg03} alt="" />
              </div>
              <div>
                  <img src={ddg04} alt="" />
              </div>
          </Carousel>
        </section>
        <section id="guide" className="main">
          <header className="major">
            <h2>記事、SNS投稿、動画配信等のガイドライン</h2>
          </header>
          <p>このゲームは推理ゲームです。未プレイの方が楽しめるように、下記をお願いしています。</p>
          <ul>
            <li>動画配信はサムネイルやタイトル、説明文等に犯人や核心などの《ネタバレ》を含めないように配慮をお願いします(動画内容には含まれていても問題ありません)</li>
            <li>記事、SNSの投稿内容は《ネタバレ》への配慮をお願いします</li>
            <li>追加シナリオ(有料コンテンツ)「はじまりの探偵少女 -Before the Game-」の内容公開はご遠慮ください</li>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default DeathDetectiveGirl
